@font-face {
    font-family: bebasFont;
    src: url(./bebas-neue/BebasNeue-Regular.ttf);
}
@font-face {
    font-family: montserrat;
    src: url(./Montserrat-Medium.ttf);
}

body{
    background-color: var(--bg);
    height:100%;
}
.login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    
}
.login-inputs{
    background-color: var(--bg-elevated);
    height:40%;
    width:clamp(300px, 30vw, 90vw );
    padding: 5px;
    margin: 15px;
    border-radius: 8px;
    font-size: 40px;
    font-family:bebasFont;
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-submit{
    color: var(--bg-accent);
    background-color: var(--bg);
    width: clamp(250px, 20%, 30%);
    border: 6px solid var(--bg-accent);
    font-size: 80px;
    font-family:bebasFont;
    text-align: center;
    color: var(bg-dark);
    border-radius: 8px;
    transition: background var(--speed);
    transition: color var(--speed);
}

.login-submit:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
  }
.input{
    font-size: 40px;
    font-family:roboto;
    width:60%;
    margin: 3%;
    border-radius: 8px;
    border: none;
}

.userTitle{
    font-family:roboto;
    color: var(--text-color);
}
.new-user-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: clamp(5px, 2vh, 10px);
}
.new-user-button{
    color: var(--bg-accent);
    background-color: var(--bg);
    width: clamp(200px, 15%, 30%);
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    margin: 1%;
    transition: background var(--speed);
    transition: color var(--speed);
}
.new-user-button:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}
.username-available{
    background-color: #EA526F;
    color: var(--bg-dark);
    font-family:bebasFont;
    font-size: 2em;
    border-radius: 4px;
    margin: .25%;
    padding:.5%;
}
.guest-login{
    color: var(--bg-accent);
    font-family:bebasFont;
    font-size: 1.5em;
    text-align: center;
}