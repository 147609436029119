.header-text{
    font-size: 6em;
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}
.workoutplan-name{
    font-family:roboto;
    color: var(--text-color);
    font-size: 2em;
    padding-left: 20px;
    margin:1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.workoutplan-name-child{
    background-color: var(--bg-elevated);
    border-radius: 4px;
    margin:1%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.button{
    color: var(--bg-accent);
    background-color: var(--bg);
    width: 15%;
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    text-align: center;
    margin:1%;
    padding: .5%;
    transition: background var(--speed);
    transition: color var(--speed);
}
.button:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}
.add-exercise-button{
    color: var(--bg-accent);
    background-color: var(--bg);
    width: 35%;
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    text-align: center;
    margin:1%;
    padding: .5%;
    transition: background var(--speed);
    transition: color var(--speed);
}
.add-exercise-button:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}
.workoutplan-day{
    font-family:roboto;
    color: var(--text-color);
    font-size: 1.5em;
    padding: 20px;
    margin:1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-elevated);
    border-radius: 8px;
    text-align: center;
}
.workoutdays-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}