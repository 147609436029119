.headerText{
    font-size: 6em;
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

.workoutDayHeader{
    font-size: 3em;
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}
.wp-change{
    display: flex;
    
    justify-content: left;

}
.change-wp-button{
    
    color: var(--bg-accent);
    background-color: var(--bg);
    width: clamp(250px, 20vw, 1000px);
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    margin: 1%;
    
    padding: .5%;
}

.wp-dropdown{
    display: flex;
    justify-content: left;
}
.wp-dropdown-list{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-light);
    border-radius: 8px;
    width: clamp(250px, 20vw, 1000px);
    padding: .5em;
}
.wp-dropdown-item{
    color: var(--bg-dark);
    border-radius: 8px;
    transition: background var(--speed);
    width:90%;
    text-align: center;
    padding: .5em;
}
.wp-dropdown-item:hover{
    background-color: var(--bg-accent-sat);
}
.workout-days{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.workout-day{
    background-color: var(--bg-elevated);
    padding: 20px;
    margin:1%;
    border-radius: 8px;

}