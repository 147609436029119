.workoutHeader {
    text-align: left; 
    margin: 0px;
}
.headerText-workout{
    font-size: clamp(1em,7vh,4em);
    font-family:bebasFont;
    color: var(--bg-accent);
    margin-left: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.workout-header-div{
    display: flex;
    flex-direction: row;
    width:75%;
    margin:.5%;
}
.workout-header-input{
    font-family: bebasFont;
    font-size: clamp(1em,7vh,2em);
    color: var(--bg-accent);
    background-color: var(--bg-dark);
    text-align: center;
    border: 2px solid var(--bg-accent);
    flex-grow: 1;
    width: auto;
    border-radius: 4px;
    margin: .5%;
    transition: background var(--speed);
    transition: color var(--speed);
}

.workout-header-input:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}
.exercise-tiles-parent{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: .5%;

}
.exercise-tiles{
    display:flex;
    flex-direction: row;
}
.reps-weight-label{
    color: var(--bg-accent);
    font-family: bebasFont;
    margin-left: .6%;
    margin-right: 1%;
}
.reps-weight-label div{
    margin-top: 0;
    margin-bottom: 40px;
}
.reps-weight-label h3{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.4em;
}
.workout-set-tile-header{
    text-align: center;
    margin:.5%;
}
.workout-set-tile{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
    display:flex;
    flex-direction: column;
    max-width: 200px;
    margin:5px;
    border-radius: 5px;
    position: relative;
}
.workout-set-tile-rep-input{
    text-align: center; 
    padding-top:5px;
    padding-left: 5px;
    padding-right: 5px;

}
.workout-set-tile-weight-input{
    text-align: center; 
    padding-bottom:5px;
    padding-left: 5px;
    padding-right: 5px;
}
.workout-set-tile-input-field{
    text-align: center;
}
.plus-icon-button{
    background-color: var(--bg-accent);
    border-radius:50%;
    --button-size: calc(var(--nav-size)*.6);
    width: var(--button-size);
    height: var(--button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 1%;
}
.minus-icon-button{

    --button-size: calc(var(--nav-size)*.3);
    width: var(--button-size);
    height: var(--button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 1%;
    position: absolute;
    top: -3px;
    right: 0px;
}

.previousExerciseHeader{
    text-align: left; 
    margin-top: 0;
    margin-bottom: 0;
    
}
.prev-table{
    margin-left: 5px;
    display: flex;
}
.prev-table-actual{
    width:95%;
}
.previousExercise{
    font-size: clamp(1em, 6vh, 2em);
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.tableHeader{
    background-color: var(--bg-accent-sat);
    font-family:roboto;
    color: var(--text-color);
    font-size: 2em;
    border-radius: 4px;
    
}

.lightCellPrev{
    background-color: var(--bg-light);
    color: var(--text-color);
    font-family:roboto;
    text-align: center;
    font-size: 1em;
    padding: .25rem;
}

.darkCellPrev{
    background-color: var(--bg-elevated);
    color: var(--text-color);
    font-family:roboto;
    text-align: center;
    font-size: 1em;
    padding: .25rem;
}

.notes-settings{
    display: flex;
    flex-direction: column;

}
.notes-settings-row{
    display: flex;
    flex-direction: row;
    width:95%;
    margin:.5%;
}
.notes-settings-header{
    background-color: var(--bg-elevated);
    font-family:roboto;
    color: var(--text-color);
    font-size: clamp(1em,3vh,2em);
    margin-right: 5px;
    width: 12%;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.notes-settings-inputs{
    font-family:roboto;
    color: var(--bg-dark);
    font-size: 2em;
    border-radius: 8px;
    width:85%;
    border:none;
}
.no-data{
    font-size: 2em;
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.submit-button{
    color: var(--bg-accent);
    background-color: var(--bg);
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    margin: 1%;
    transition: background var(--speed);
    transition: color var(--speed);
}

.submit-button:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}

.previous-data{
    display: flex;
    flex-direction: row;
}

.prev-data-table{
    width: 50%;
}
.prev-data-chart{
    width: 50%;
}

@media only screen and (max-width:400px) {
    .previous-data{
        flex-direction: column;
    }
    .prev-data-table{
        width: 95vw;
    }
    .tableHeader{
        font-size: 1em;
    }
    .lightCellPrev{
        font-size: .9em;
    }
    .darkCellPrev{
        font-size: .9em;
    }
    .prev-data-chart{
        width: 95vw;
        height: 30vh;
    }
    .notes-settings-row{
        width:95vw;
    }
    .notes-settings-header{
        width: 25vw;
    }
    .notes-settings-inputs{
        width:60vw;
    }
}