@font-face {
    font-family: bebasFont;
    src: url(./bebas-neue/BebasNeue-Regular.ttf);
}
html,body {
    padding:0;
    margin:0;
    height:100%;
}


.homeHeader{
    text-align: center;
    font-size: clamp(.5em,8vh,5em);
    font-family:bebasFont;
    color: var(--bg-accent);
    background-color: none;
    margin-top: 0px;
    margin-bottom: 10px;
    
}
.workoutDays{
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: wrap;
}
@media only screen and (max-width:400px) {
    .workoutDays{
        flex-direction: column;
    }
    .link{
        width: 80%;
    }
    .nextLink{
        width: 80%;
    }
}


.link{
    color: var(--text-color);
    background-color: var(--bg-elevated);
    color: var(--text-color);
    font-size: 5em;
    word-wrap: break-word;
    height:60vh;
    font-family:bebasFont;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5%;
    border: 10px solid var(--bg-light);
    border-radius: 8px;
    transition: background var(--speed);
    transition: color var(--speed);
    transition: border var(--speed);
}
.link:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
    border: 10px solid var(--bg-accent);
  }

.nextLink{
    color: var(--text-color);
    background-color: var(--bg-elevated);
    font-size: 5em;
    height:60vh;
    word-wrap: break-word;
    font-family:bebasFont;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5%;
    border: 10px solid var(--bg-accent);
    border-radius: 8px;
    transition: background var(--speed);
    transition: color var(--speed);
    transition: border var(--speed);
}
.nextLink:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
    border: 10px solid var(--bg-accent);
  }

.wp-change{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.change-wp-button{
    text-align: center;
    color: var(--bg-accent);
    background-color: var(--bg);
    width: clamp(250px, 20vw, 1000px);
    border: 2px solid var(--bg-accent);
    font-size: 30px;
    font-family:bebasFont;
    color: var(bg-dark);
    border-radius: 4px;
    margin: 1%;
    padding: .5%;
    transition: background var(--speed);
    transition: color var(--speed);
}
.change-wp-button:hover{
    background-color: var(--bg-accent);
    color: var(--bg-dark);
}

.wp-dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.wp-dropdown-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-light);
    border-radius: 8px;
    width: clamp(250px, 20vw, 1000px);
    padding: .5em;
}
.wp-dropdown-item{
    color: var(--bg-dark);
    border-radius: 8px;
    transition: background var(--speed);
    width:90%;
    text-align: center;
    padding: .5em;
}
.wp-dropdown-item:hover{
    background-color: var(--bg-accent-sat);
}