body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --bg: #0B0C10;
  --bg-elevated: #1F2833;
  --bg-dark: #0B0C10;
  --bg-white: #ffffff;
  --bg-light: #C5C6C7;
  --bg-accent: #66FCF1;
  --bg-accent-sat: #45A29E;
  --text-color: #FFFFFF;
  --nav-size: 60px;
  --border: 1px solid #1C1F22;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--bg-elevated);
  text-decoration: none;
}

.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  
}

.navbar-nav {
  max-width: 100%;
  height:100%;
  display: flex;
  justify-content: flex-end;
  
}

.nav-item {
  width: calc(var(--nav-size)*.8);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.nav-button {
  --button-size: calc(var(--nav-size)*.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--bg-accent);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button {
  --button-size: calc(var(--nav-size)*.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--bg-white);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover{
  filter: brightness(1.2);

}

.icon-buttton svg {
  fill:var(--text-color);
  width: 20px;
  height: 20px;
}

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color:var(--bg-elevated);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow:hidden;
  transition: height var(--speed) ease;
}

.menu-item{
  height: 50px;
  display:flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: .5rem;
  color: var(--text-color);
}

.menu-item:hover {
  background-color: var(--bg-accent-sat);
}
.icon-right{
  margin-left: auto;
}
.menu-item icon-button:hover{
  filter: none;
}

.menu-primary-enter{
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active{
  transform: translateX(0%);
  transition:all var(--speed) ease;
}
.menu-primary-exit{
  position: absolute;
}
.menu-primary-exit-active{
  transform: translateX(-110%);
  transition:all var(--speed) ease;
}

.menu-secondary-enter{
  transform: translateX(110%);
}
.menu-secondary-enter-active{
  transform: translateX(0%);
  transition:all var(--speed) ease;
}
.menu-secondary-exit{

}
.menu-secondary-exit-active{
  transform: translateX(110%);
  transition:all var(--speed) ease;
}