.header-text{
    font-size: 4em;
    font-family:bebasFont;
    color: var(--bg-accent);
    margin: 10px;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
}
.text{
    font-size: 2em;
    font-family: roboto;
    color: var(--bg-light);
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.table-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    
}
.calendar-table{
    color: var(--text-color);
    border-collapse: collapse;
    width:90%;
    margin: 10px;
    border: 2px solid #ccc;
    width:85%;
}
.calendar-header{
    font-size:4em;
    font-family:roboto;
    border: 2px solid #ccc;
    padding: 10px;
    text-align: center;
    width: 33%;
}
.calendar-sub-header{
    font-size: 2em;
    font-family:roboto;
    text-align: center;
    border: 2px solid #ccc;
    width:4.76%;
}

.calendar-cell-out{
    text-align: center;
    border-spacing: 3px;
}
.calendar-cell-true{
    background-color: var(--bg-accent);
    text-align: center;
    color: var(--bg-dark);
    font-size: 1.5em;
    border-radius:10%;
    border-spacing: 3px;
}
td{
    font-family: roboto;
}
.calendar-cell-false{
    text-align: center;
    font-size: 1.5em;
    border-spacing: 3px;
}
.calendar-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bg-elevated);
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.ratio{
    color:var(--text-color);
    font-family:roboto;
    font-size: 3em;
    text-align: center;
}
.month-button{
    background-color: var(--bg-accent);
    border-radius: 8px;
    width:5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
    
}
.icon{
    width:100%;
    
}
